.cs-widget_title_get {
    font-size: 18px; /* Adjust as needed */
    position: relative;
    padding-bottom: 5px; /* Space between text and underline */
    margin-bottom: 10px; /* Space below the title */
  }
  
  .cs-widget_title_get.underline::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 29%;
    height: 2px; /* Thickness of the underline */
    background-color:white; /* Color of the underline */
    /* Optional: Add some "happiness" by applying a slight curve or shadow */
    border-radius: 2px;
  }
 
