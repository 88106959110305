.category-container {
    margin: 30px;
    font-family: sans-serif;
  }
  
  .input-container {
    margin-bottom: 20px;
  }
  
  .input-container input {
    display: block;
    margin: 5px 0;
    padding: 6px;
    font-size: 14px;
    background-color: transparent;
    width: 300px;
    border: 1px solid #7e6f6f;
    border-radius: 5px;
    color: rgb(243, 235, 235);
  }
  
  .tree {
    margin: 1em;
  }
  
  .tree-node {
    position: relative;
    padding: 5px 0 5px 20px;
  }
  
  .tree input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
  
  .tree input ~ .branch {
    display: none;
  }
  
  .tree input:checked ~ .branch {
    display: block;
  }
  
  .tree-label {
    position: relative;
    display: inline-block;
    background: #180303;
    cursor: pointer;
    padding-left: 25px;
  }
  
  .tree-label:hover {
    color: #666;
  }
  
  .tree-node.parent > input + .tree-label:before {
    background: #000;
    color: #fff;
    position: absolute;
    left: -20px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    content: "+";
    text-align: center;
    line-height: 16px;
  }
  
  .tree-node.parent > input:checked + .tree-label:before {
    content: "−";
  }
  .branch {
    margin-left: 20px;
    padding-left: 10px;
    border-left: 1px solid #777;
  }
  .child {
    margin-left: 20px;
  }
  .tree-node.child > .tree-label:before {
    content: none !important;
  }
  .create-cat-btn {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .create-cat-btn:hover {
    background-color: #0056b3;
  }
  .back-dashboard{
   
      background-color: #2C5D4C;
      border-radius: 5px;
      padding: 3px 15px;

  }
  .back-dashboard:hover{
    color: white;
    

  }