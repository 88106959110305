.cs-pagination_box {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
  }
  
  .cs-pagination_item {
    padding: 10px 15px;
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    background-color: white;
    color: white;
    cursor: pointer;
    transition: 0.3s;
    text-decoration: none;
  }
  

  .cs-pagination_item:hover:not(.active):not(.disabled) {
    background-color: black;
  }
  
  .cs-pagination_item.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .share-buttons {
    display: flex;
    gap: 10px; 
    margin-top: 20px; 
    margin-bottom: 20px;
  }
  .social-icon-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.share-blog-button{

  background-color: black;
  outline: none;

  color: white;

}
  