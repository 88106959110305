.privacy-container {
    max-width: 1130px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.header{
   
    text-align:start;
    margin-bottom: 20px;
}
h4{
   margin-top: 10%; 
  
}
.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.logo img {
    max-width: 100%;
    height: auto;
}
/*  */

.policies{
    max-height: 60vh;
    overflow-y: auto;
    padding: 10px;
    border-top: 1px solid white;
}

.content h2 {
    margin-top: 20px;
}

.content p {
    line-height: 1.6;
}

/* footer {
    text-align: center;
    margin-top: 20px;
} */

@media (max-width: 600px) {
    .header{
        margin-top: 25%;
    }
    .privacy-container {
        padding: 10px;
    }

    .content {
        padding: 5px;
    }
    .privacy-container header h3 {
        margin-top: 50px;

    }
}
