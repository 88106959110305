@media screen and (max-width:600px){
    .footor_logo img{

        transform: translateX(-95%);
    
    } 
}



    
