.arc_main_container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 90vh;
    }

    .arc-bike-content-container {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    transition: left 1s ease-in-out;
    opacity: 0;
    }
    
    .arc-bike-content-container.active {
    left: 0;
    opacity: 1;
    }
    
    .arc-bike-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #080808;
    padding-bottom: 20px; /* Add padding to move the image upwards */
    margin-top: -100px; /* Move the image upwards */
    }
    
    .arc-bike-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.5s ease;
    }
    
    .arc-bike-content-container:first-child .arc-bike-image img {
    padding-left: 100px; /* Adjust the padding value as needed */
    }
    
    .arc-bike-image img:hover {
    transform: scale(1.1);
    }
    
    .arc-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #080808;
    color: white;
    padding: 20px;
    }
    
    .arc-text-wrapper {
    opacity: 0;
    transform: translateY(100%);
    animation: slideUp 1s ease-out forwards;
    }
    
    @keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
    }
    
    .arc-h1 {
    font-size: 2.0rem;
    translate: -10% 0 0;
    margin-bottom: 20px;
    position: relative;
    }
    
    .arc-h1::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 2px;
    background-color: #fff;
    transition: width 0.3s ease;
    }
    
    .arc-h1:hover::after {
    width: 100%;
    }
    
    .arc-p {
    text-align: start;
    translate: -10% 0 0;
    }
    
    .arc-a {
    font-size: 1rem;
    color: #f4907f;
    text-decoration: none;
    padding: 50px 50px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    }
    
    .arc-button {
    background-color: red;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 2rem;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    }
    
    .arc-button.prev {
    left: 10px;
    }
    
    .arc-button.next {
    right: 10px;
    }
    
    .arc-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
    }

    @media screen and (max-width:600px) {
        .arc-bike-content-container.active {
            display: flex;
            flex-direction: column;
            height: fit-content;
            margin-top: 100px;
        }
        .arc-content{
            /* margin-left: 20%; */
            padding-left: 20%;
            background-color: #0e0e0e;
        }
        .image{
            margin-right: 20%;
        }
        .arc-h1{
            font-size: 15pt;
        }
        .arc-p{
            font-size: 10pt;
        }
        .arc-button {
            position: absolute;
            top: 50%;
            transform: translateY(-20%);
            /* background-color: rgba(0, 0, 0, 0.5); */
            background-color: #0e0e0e;
            color: white;
            border: none;
            font-size: 2rem;
            padding: 10px;
            cursor: pointer;
            z-index: 10;
            }
            
            .arc-button.prev {
            left: 10px;
            }
            
            .arc-button.next {
            right: 10px;
            }
            
            .arc-button:hover {
            /* background-color: rgba(0, 0, 0, 0.8); */
            background-color: #0e0e0e;
            }
        
    }
    @media screen and (min-width: 601px) and (max-width: 1024px) {
        .arc-h1 {
            font-size: 1.75rem;
        }
        .arc-p {
            font-size: 12pt;
        }
        .arc-button {
            font-size: 1.75rem;
            padding: 12px;
        }
    }
    
    /* Large devices (desktops) */
    @media screen and (min-width: 1025px) {
        .arc-h1 {
            font-size: 2rem;
        }
        .arc-p {
            font-size: 14pt;
        }
        .arc-button {
            font-size: 2rem;
            padding: 15px;
        }
    }