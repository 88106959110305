.table-container {
    margin-top: 30%;
    width: 90%;
    margin: 20px auto;
    border-radius: 8px;
    overflow: hidden;
  }

  .create-new-btn, .create-category {
    background-color:#2C5D4C;
    color: white;
    border: none;
    padding: 5px 15px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
    margin-right: 2%;
    min-width: 150px;
    flex-shrink: 0;
    width: auto;
  

  }
  .logout-btn{
    background-color: red;
    border-radius: 5px;
    color: white;
    padding: 3px 10px;
  }
  
  .create-new-btn:hover {
    background-color: #2C5D4C;
  }
  .blog-table {
    
    width: 100%;
    border-collapse: collapse;
   
    background-color: black;
    /* background-color: green; */
    color: white;
  }
  
  .blog-table th, .blog-table td {

    padding: 10px;
    text-align: left;
  }
  
  .blog-table th {
    /* background-color: #f4f4f4; */
    font-weight: bold;
  }
  
  .title-link {
    color: white;
    font-weight: normal;
    cursor: pointer;
  }
  
  .title-link:hover {
    text-decoration: underline;
  }
  
  .actions {
    font-size: 12px;
    margin-top: 5px;
  }
  
  .action-link {

    color: white;
    text-decoration: none;
    margin: 0 5px;
  }
  
  .action-link:hover {
    text-decoration: underline;
  }
  
  .delete {
    color: red;
  }
  .selectType{
    background-color: #2C5D4C;
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
    color: white;
    font-size: 14px;
  }




  .create-new-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; 
    padding: 10px;
    color: white;
    gap: 10px;
  }
  
  .left-section {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap; 
  }
  
  .profile-section {
    flex-grow: 1;
    text-align: center;
    font-size: 16px;
    
  }
  
  .right-section {
    display: flex;
    justify-content: flex-end;
  }
  

  @media (max-width: 768px) {
    .create-new-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .left-section, .right-section {
      width: 100%;
      justify-content: center;
    }
  
    .profile-section {
      order: -1; 
      margin-bottom: 10px;
    }
    .title-link {
    
      font-weight: normal;
   
    }
  }
  
  @media (max-width: 480px) {
    .create-new-btn, .create-category, .logout-btn {
      width: 100%; 
      font-size: 13px;
      padding: 10px;
    }
  
    .selectType {
      width: 100%;
    }
  }