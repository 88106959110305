/* 
.login-main-container {

    background-image: url(''); 
    color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-container {
    width: 100%;
    max-width: 400px;
    padding: 40px;
    background-color: #1e1e1e; 
  
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
    text-align: center;
  }
  
  .login-container h1 {
    margin-bottom: 30px;
    font-size: 1rem;
    color: #ffffff; 
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group input {
    width: 100%;
    padding: 7px;
    border: 1px solid #333333; 
    background-color: #2a2a2a; 
    color: #e0e0e0; 
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
   
  }
  
  .form-group input::placeholder {
    color: #777777; 
  }
  
  .form-group input:focus {
    border-color: #555555;
  }
  
  .login-button {
    width: 100%;
    padding: 8px;
    background-color: #333333;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #444444;
  }
  
  .login-button:active {
    background-color: #555555;
  }

    */


















































    .syra-body-image {
      background-image: url('https://img.freepik.com/free-photo/car-empty-road-dark-atmosphere_23-2150914416.jpg?t=st=1738918943~exp=1738922543~hmac=a199836cce3be4a650e8cdcbc5ab8cab26397505c6fda64cc79eb5ba37816ddf&w=740'); 
      background-size: cover;
      background-position: center;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 20px;
    }
    
    .syra-body-image::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
    
    .syra-login-container {
      position: relative;
      z-index: 2;
      width: 100%;
      max-width: 400px; /* Limits form width */
    }
    
    .syra-login-box {
      background-color: rgba(37, 33, 33, 0.8);
      padding: 25px;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      text-align: center;
      width: 100%;
    }
    
    .syra-login-box h4 {
      color: white;
      font-size: 1.5rem;
      margin-bottom: 15px;
    }
    
    .syra-input-group {
      position: relative;
      margin-bottom: 15px;
    }
    
    .syra-input-group input {
      width: 100%;
      padding: 10px 10px 10px 40px;
      border: 1px solid #ccc;
      background-color: transparent;
      border-radius: 5px;
      font-size: 16px;
      color: white;
      outline: none;
    }
    
    .syra-input-group input::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
    
    .syra-icon {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
    }
    
    .syra-login-bttn {
      width: 100%;
      padding: 10px;
      background-color: #264C62;
      color: white;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      font-weight: bold;
    }
    
    .syra-login-bttn:hover {
      background-color: #0056b3;
    }
    
    .syra-error {
      color: red;
      font-size: 14px;
      margin-bottom: 10px;
    }
    
    @media (max-width: 768px) {
      .syra-body-image {
        padding: 15px;
      }
    
      .syra-login-box {
        width: 90%;
        padding: 20px;
      }
    
      .syra-input-group input {
        font-size: 14px;
        padding: 10px 12px;
      }
    
      .syra-login-bttn {
        font-size: 14px;
        padding: 10px;
      }
      .syra-icon{
        display: none;
      }
    }
    
    @media (max-width: 480px) {
      .syra-login-box {
        width: 100%;
        padding: 15px;
        border-radius: 5px;
      }
    
      .syra-input-group input {
        font-size: 14px;
        padding: 10px;
      }
    
      .syra-login-bttn {
        font-size: 14px;
        padding: 10px;
      }
      .syra-icon{
        display: none;
      }
    }
    