
.blog-form-container {
  max-width: 100vw;
  padding: 30px;
  padding-left: 10%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
}

.blog-form-container h3 {
  text-align: start;
  margin-bottom: 30px;
  color: #ffffff;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.custom-quill-modal {
  /* width: 30 */
  min-width: 20vw;
  flex: 1;
  height: 50vh;
  max-height: 100%;
  min-height: 100%;
}
/* .form-group-modal {
  background-color: red;

  height: 100vh;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
} */
.form-group label {
  width: 150px;
  margin-right: 20px;
  font-size: 1rem;
  color: #ffffff;
}

.form-group input,
.form-group select {
  padding: 12px;
  background-color: #494848;
  border: 1px solid gray;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1rem;
  outline: none;
  width: 50vw;
  transition: border 0.3s ease;
}

.form-group input::placeholder {
  color: #ffffff;
}

.form-group input:focus {
  border-color: white;
}

.form-group .ql-toolbar.ql-snow {
  background-color: rgb(163, 159, 159);
  border: 1px solid #333333;
  border-radius: 5px 5px 0 0;
  color: #ffffff !important;
}

.form-group .ql-container.ql-snow {
  border-radius: 0 0 5px 5px;
  color: #ffffff;
  min-height: 200px;
  width: 50vw;
  background-color: #494848;
  border: 1px solid gray;

}

.form-group .ql-container.ql-snow:hover {
  border-color: white;
}

.ql-editor {
  color: #ffffff;
}

.ql-editor::before {
  color: #ffffff;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.form-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* flex: 1; */
  min-width: 100px;
}

.save-button {
  background-color: gray;
  color: #ffffff;
}

.publish-button {
  background-color: #2C5D4C;
  color: #ffffff;
}

.edit-button {
  background-color: #927b40;
  color: #ffffff;
}
.thumbnail-preview{
  width: 20vw;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
  object-fit: cover;
}
@media (max-width: 768px) {
  .form-group label {
    width: 100%;
    margin-bottom: 10px;
  }

  .form-group input, .form-group .ql-container.ql-snow ,.form-group select{
    width: 100%;
  }

  .form-button {
    width: 30%;
  }
}

@media (max-width: 480px) {
  .blog-form-container {
    padding: 20px;
  }

  .form-group label {
    font-size: 0.9rem;
  }

  .form-group input,
  .form-group select{
    font-size: 0.9rem;
    padding: 10px;
  }

  .form-button {
    font-size: 0.9rem;
    padding: 8px;
  }
}
.edit-with-preview-btn{
  background-color: transparent;
  border: 1px solid gray;
  border-radius: 5px;
  
}
/* Modal */
.custom-modal-base {
  background: black;
  border-radius: 8px;
  width: 95%;
  height: 90%;
  /* max-width: 90%; */
  margin: auto;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  }
  
  .custom-modal-base_after-open {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.3s;
  }
  
  .custom-modal-base_before-close {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.3s, transform 0.3s;
  }
  .custom-overlay-base {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  }
  
  .custom-overlay-base_after-open {
  opacity: 1;
  transition: opacity 0.3s;
  }
  
  .custom-overlay-base_before-close {
  opacity: 0;
  transition: opacity 0.3s;
  }
  
  .custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
  }
  
  .custom-modal-title {
    color: white;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  }
  
  .custom-modal-close {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  }
  
  .custom-modal-input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  }
  
  
  .custom-modal-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 10px;
  
  }
  
  .custom-modal-add-button {
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  }
  
  .custom-modal-add-button:hover {
  background: #0056b3;
  }
  
  .custom-modal-save-button {
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  align-self: flex-start;
  }
  
  .custom-modal-add-button:hover,
  .custom-modal-save-button:hover {
  background: #0056b3;
  }
  
  .custom-modal-data-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  }
  
  .custom-modal-data-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  background: #eceaea;
  }
  
  .custom-modal-data-text {
  margin: 0;
  font-size: 14px;
  }
  
  .custom-modal-delete-button {
  background: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  }
  
  .custom-modal-delete-button:hover {
  background: #b21f2d;
  }
  .line{
    /* color: red;
    width: 10%;
    height: 100%; */
    border-left: 1px solid white;
    
  }
  /* .modal{
    display: flex;
    flex-direction: row;
    background-color: red;
    width: 100%;
  } */

  .custom-modal-body {
    display: flex;
    align-items: flex-start;
    gap: 20px; 
    height: 100%;
  }
  
  .custom-quill-modal {
    width: 25vw;
    min-height: 40vh;
  }
  
  .vertical-line {
    width: 2px;
    background-color: #ccc;
    min-height: 55vh;

  }
  
  .preview-content {
    flex: 1; 
    padding: 10px;
    height: 60vh;
    max-height: 60vh;
    min-height: 60vh;
    overflow-y: auto;
    border-radius: 8px;
  }

  .back-dashboard{
   
    background-color: #2C5D4C;
    border-radius: 5px;
    padding: 3px 15px;

}
.back-dashboard:hover{
  color: white;
  }
  