.acco_main-container{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    }
    @media screen and (min-width:1600px){
      .acco_main-container{
        width:100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .accordian{
        width: 50%;
      }
    }
  .acc-heading{
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 5%;
    margin-left: 7%;
  }
  @media screen and (max-width:600px) {
    .acc-heading{
      margin-left:2%;
      
    }
  }
    
  .title-container{
    translate: 6% 0 0;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  @media screen and (min-width:1600px) {
    .title-container{
      translate: 11% 0 0;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }
  .accordian{
    max-width: 150vb;
  }
  .iteams{
    background-color:black;
    margin-top: 1%;
    padding: 1% 5%;
    border-bottom: 20px;
  }
  .title{
    color: white;
    font-size:5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
   
  }
  .title span{
  
    font-size: 20px;
  }
  .acc-content p{
    color: white;
    height:0;
    background-color: black;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
  }
  .acc-contentshow{
    height: auto;
    max-height: 1000px;
    color: white;
    transition : all 0.5s cubic-bezier(1,0,1,0);
  }